<template>
  <main>
    <trac-loading v-if="isLoading" />
    <trac-back-button>POS Dashboard</trac-back-button>
    <div>
      <div class="big-shadow mt-12 rounded-md bg-white py-12 px-16">
        <div class="flex items-center justify-between">
          <div
            class="mx-auto mt-20 flex items-center max-w-xl w-full justify-between border-b"
          >
            <div
              @click="type = 'customerLoyalty'"
              :class="{
                'light-blue text-primaryBlue border-primaryBlue border-b':
                  type == 'customerLoyalty',
              }"
              class="text-gray-500 cursor-pointer text-center py-3 w-full tracking-wide"
            >
              Customer Loyalty
            </div>
            <div
              @click="type = 'storeCredit'"
              :class="{
                'light-blue text-primaryBlue border-primaryBlue border-b':
                  type == 'storeCredit',
              }"
              class="text-gray-500 cursor-pointer text-center w-full py-3 tracking-wide"
            >
              Store Credit
            </div>
          </div>
          <div
            v-if="type == 'customerLoyalty'"
            class="flex items-center absolute -mt-10 right-0 mr-20"
          >
            <div class="text-xs text-primaryBlue mr-6">Enable loyalty</div>
            <trac-toggle
              :active="allowSetLoyalty"
              @newval="toggleLoyalty"
            ></trac-toggle>
          </div>
          <div v-else class="flex items-center absolute -mt-10 right-0 mr-20">
            <div class="text-xs text-primaryBlue mr-6">Enable Store Credit</div>
            <trac-toggle
              :active="allowStoreCredit"
              @newval="setStoreCreditPoints"
            ></trac-toggle>
          </div>
        </div>
        <div v-if="type == 'customerLoyalty'">
          <p
            class="mt-5 text-center text-xs text-gray-700 tracking-wide leading-5"
          >
            Allow customers to earn loyalty points when purchasing products.<br />
            You can set the monetary value earned on individual products from
            the
            <br />
            Edit Product page or from a price book.
          </p>
          <div
            class="mt-12 mx-auto w-full max-w-xl bg-gray-100 flex flex-col items-center py-8"
          >
            <div class="border-b pb-4">
              <p class="text-sm px-16 tracking-wide">
                Set a loyalty percentage
              </p>
            </div>
            <div class="relative flex mt-8 items-center">
              <input
                :disabled="!allowSetLoyalty"
                class="border rounded-md text-sm h-12 text-center"
                :class="
                  allowSetLoyalty ? 'border-primaryBlue' : 'border-gray-400'
                "
                placeholder="0.0"
                type="text"
                v-model.number="loyaltyPoints"
              />
              <div class="absolute text-primaryGray right-0 mr-4">%</div>
            </div>
            <!-- <svg
          class="my-6"
          width="24"
          height="19"
          viewBox="0 0 24 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.25 0.949999H23.3V5.95H0.25V0.949999ZM0.25 13.05H23.3V18.05H0.25V13.05Z"
            fill="#333333"
          />
        </svg>
        <div class="text-sm mt-4 tracking-wide">1.00 NGN Loyalty</div> -->
            <div class="flex px-10 mt-8">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.99935 0.666992C4.39685 0.666992 0.666016 4.39783 0.666016 9.00033C0.666016 13.6028 4.39685 17.3337 8.99935 17.3337C13.6018 17.3337 17.3327 13.6028 17.3327 9.00033C17.3327 4.39783 13.6018 0.666992 8.99935 0.666992ZM9.83268 13.167H8.16602V8.16699H9.83268V13.167ZM9.83268 6.50033H8.16602V4.83366H9.83268V6.50033Z"
                  fill="#979797"
                />
              </svg>
              <div class="text-xs ml-3 pr-6 text-primaryGray tracking-wide">
                <p>
                  This means that for every NGN 100 purchase your customer
                  makes, they get {{ defaultLoyalty | formatPrice }} in loyalty
                  points.
                </p>
                <!-- <p class="mt-6">X.XX = % number e.g. 1.50 (for 1.5%), 2.00 (for 2%).</p> -->
              </div>
            </div>
          </div>
          <div
            class="w-full flex justify-between items-center max-w-xl mx-auto mt-12 pb-12"
          >
            <!-- :disabled="businessDetails.loyalty_active || loyaltyPoints === '' || loyaltyPoints === 0" -->
            <trac-button variant="outline">
              <span class="px-12"> Cancel </span>
            </trac-button>
            <trac-button
              :disabled="
                loyaltyPoints === '' || loyaltyPoints === 0 || !allowSetLoyalty
              "
              @button-clicked="setLoyaltyPoints"
            >
              <span class="px-12"> Save </span>
            </trac-button>
          </div>
        </div>
        <div
          v-else
          class="max-w-xl w-full mx-auto text-center pt-12 pb-64 text-xs text-gray-700 tracking-wide leading-5"
        >
          With store credit you can assign a monetary value as a credit to the
          customer. So the customer uses that credit to make purchases until the
          credit is exhausted or its validity expires.

          <!-- <div
            class="mt-24 h-full mb-24 mx-12 items-center flex flex-row justify-between"
          >
            <div class="flex-column mr-2 w-10/12">
              <trac-dropdown-exteneded
                :neededProperty="'name'"
                placement="left"
                :options="stores"
                @optionSelected="setStore($event)"
              >
              </trac-dropdown-exteneded>
            </div>
            <div class="flex-column z-50">
              <trac-button
                :disabled="!selectedStore"
                @button-clicked="setStoreCreditPoints"
                class="uppercase"
                >Activate</trac-button
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      type: "customerLoyalty",
      isLoading: false,
      selectedStore: null,
      businessDetails: false,
      allowSetLoyalty: false,
      allowStoreCredit: false,
      loyaltyPoints: "",
      defaultLoyalty: 0,
    };
  },
  async created() {
    this.isLoading = true;
    this.businessDetails = GET_USER_BUSINESS_DATA();
    this.allowSetLoyalty = this.businessDetails.loyalty_active;
    this.getStoreCredit();
    if (this.allowSetLoyalty) {
      await this.fetchLoyaltyData();
    }
    this.isLoading = false;
  },
  methods: {
    toggleLoyalty() {
      this.allowSetLoyalty = !this.allowSetLoyalty;
    },
    setStore(store) {
      this.selectedStore = store;
    },
    // async fetchAllStores() {
    //   await this.$store.dispatch("FETCH_ALL_STORES");
    //   const res = this.$store.getters["GET_ALL_STORES"];
    //   if (res.status) {
    //     this.stores = res.data;
    //   } else {
    //     alert(res.message);
    //   }
    // },
    async setLoyaltyPoints() {
      this.isLoading = true;
      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      fetch(
        `https://tractionloyalty-stage.herokuapp.com/business_loyalty?callbackURI=https://tractionapp-stage.herokuapp.com/products/loyalty_points/${GET_USER_BUSINESS_ID()}/${
          this.loyaltyPoints
        }`,
        {
          method: this.allowSetLoyalty ? "PATCH" : "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            business: GET_USER_BUSINESS_ID(),
            point: this.loyaltyPoints,
          }),
        }
      )
        .then((res) => res.json())
        .then((payload) => {
          if (payload.status === 401) {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json;charset=utf-8");
            myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

            // Fetch Token
            fetch(
              `https://tractionapp-stage.herokuapp.com/auth/loyalty/access`,
              {
                method: "POST",
                headers: myHeaders,
              }
            )
              .then((res) => res.json())
              .then((data) => {
                SAVE_LOCAL_DB_DATA("secondary-auth-token", data);
                this.setLoyaltyPoints();
              })
              .catch((err) => {});
            return;
          }

          // alert(
          //   payload.Message === "" || !payload.Message
          //     ? `Loyalty points ${
          //         this.allowSetLoyalty ? "updated" : "added"
          //       } successfully.`
          //     : payload.Message
          // );

          eventBus.$emit("trac-alert", {
            message:
              payload.Message === "" || !payload.Message
                ? `Loyalty points ${
                    this.allowSetLoyalty ? "updated" : "added"
                  } successfully.`
                : payload.Message,
          });

          if (payload.Success) {
            // Set local data
            this.businessDetails = {
              ...this.businessDetails,
              loyalty_active: true,
            };

            this.defaultLoyalty = this.loyaltyPoints;

            SAVE_LOCAL_DB_DATA(
              "traction-app-current-user-business-data",
              this.businessDetails
            );
          }
          this.isLoading = false;
        })
        .catch((err) => {
          // (err);
          // alert(err);
          this.isLoading = false;
        });
    },
    getStoreCredit() {
      this.isLoading = true;
      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      fetch(
        `https://tractionloyalty-stage.herokuapp.com/store_credit/${GET_USER_BUSINESS_ID()}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((payload) => {
          if (payload.Success) {
            // Set local data
            // alert(payload.Message);
            this.allowStoreCredit = payload.Data.active;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          // (err);
          // alert(err);
        eventBus.$emit('trac-alert', { message: err });
          this.isLoading = false;
        });
    },
    async setStoreCreditPoints() {
      this.isLoading = true;
      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      fetch(
        `https://tractionloyalty-stage.herokuapp.com/store_credit/${GET_USER_BUSINESS_ID()}`,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            active: !this.allowStoreCredit,
          }),
        }
      )
        .then((res) => res.json())
        .then((payload) => {
          if (payload.Success) {
            // Set local data
            alert(payload.Message);
            this.allowStoreCredit = payload.Data.active;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          // (err);
          // alert(err);
        eventBus.$emit('trac-alert', { message: err });
          this.isLoading = false;
        });
    },
    async fetchLoyaltyData() {
      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const res = await fetch(
        `https://tractionloyalty-stage.herokuapp.com/business_loyalty?business=${GET_USER_BUSINESS_ID()}`,
        {
          headers: myHeaders,
        }
      );
      const data = await res.json();

      if (data.Success) {
        this.loyaltyPoints = data.Data.BasePoint;
        this.defaultLoyalty = data.Data.BasePoint;
      } else {
        // alert(data.Message || data.Errors);
        eventBus.$emit('trac-alert', { message: data.Message || data.Errors });
      }
    },
  },
};
</script>

<style>
.light-blue {
  background: rgba(37, 59, 149, 0.04);
}
</style>
